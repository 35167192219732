import React, { Component } from 'react';
import { Form, Container, Row, Col, Card } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import Slider from '@material-ui/core/Slider';
import { Link } from 'react-router-dom'
import Plant from './Plant.json';
import { GoogleAd } from './Components';


export default class FindPlants extends Component {

    state = {
        tempvalue: [1, 50],
        heightvalue: [0.2, 20],
        checkedcatall: true,
        checkedlightall: true,
        checkedhumidall: true,
        Categories: [
            { id: 1, value: "Air Purifier", checked: true },
            { id: 2, value: "Cacti", checked: true },
            { id: 3, value: "Climbers", checked: true },
            { id: 4, value: "Ferns", checked: true },
            { id: 5, value: "Flowering", checked: true },
            { id: 6, value: "Foilage", checked: true },
            { id: 7, value: "Fruit Bearing", checked: true },
            { id: 8, value: "Indoor Palm", checked: true },
            { id: 9, value: "Medicinal", checked: true },
            { id: 10, value: "Succulent", checked: true }
        ],
        Light: [
            { id: 12, value: "Bright", checked: true },
            { id: 13, value: "Partial Shade", checked: true },
            { id: 14, value: "Decent", checked: true },
            { id: 15, value: "Indirect", checked: true },
            { id: 16, value: "Direct Sun", checked: true },
        ],
        Humidity: [
            { id: 17, value: "Low", checked: true },
            { id: 18, value: "Normal", checked: true },
            { id: 19, value: "High", checked: true }
        ]
    }

    handleAllCheckedCategory = (event) => {
        let Categories = this.state.Categories
        Categories.forEach(item => item.checked = event.target.checked)
        this.setState({
            Categories: Categories,
            checkedcatall: event.target.checked
        })
    }

    handleCheckChieldElementCategory = (event) => {
        let Categories = this.state.Categories
        Categories.forEach(item => {
            if (item.value === event.target.value) {
                item.checked = event.target.checked
            }
            if (item.checked === false) {
                this.setState({ checkedcatall: false })
            }
        })
        this.setState({ Categories: Categories })
    }
    handleAllCheckedLight = (event) => {
        let Light = this.state.Light
        Light.forEach(item => item.checked = event.target.checked)
        this.setState({
            Light: Light,
            checkedlightall: event.target.checked
        })
    }

    handleCheckChieldElementLight = (event) => {
        let Light = this.state.Light
        Light.forEach(item => {
            if (item.value === event.target.value) {
                item.checked = event.target.checked
            }
            if (item.checked === false) {
                this.setState({ checkedlightall: false })
            }
        })
        this.setState({ Light: Light })
    }

    handleAllCheckedHumidity = (event) => {
        let Humidity = this.state.Humidity
        Humidity.forEach(item => item.checked = event.target.checked)
        this.setState({
            Humidity: Humidity,
            checkedhumidall: event.target.checked
        })
    }

    handleCheckChieldElementHumidity = (event) => {
        let Humidity = this.state.Humidity
        Humidity.forEach(item => {
            if (item.value === event.target.value) {
                item.checked = event.target.checked
            }
            if (item.checked === false) {
                this.setState({ checkedhumidall: false })
            }
        })
        this.setState({ Humidity: Humidity })
    }


    handletempChange = (event, newValue) => {
        this.setState({
            tempvalue: newValue
        })
    };
    tempvaluetext = (value) => {
        return `${value}°C`;
    }
    handleheightChange = (event, newValue) => {
        this.setState({
            heightvalue: newValue
        })
    };
    heightvaluetext = (value) => {
        return `${value}°C`;
    }
    render() {
        var categoriesselected = [];
        let Categories = this.state.Categories
        Categories.forEach(item => {
            if (item.checked === true) {
                categoriesselected.push(item.value)
            }
        })
        var lightselected = [];
        let Light = this.state.Light
        Light.forEach(item => {
            if (item.checked === true) {
                lightselected.push(item.value)
            }
        })
        var humidityselected = [];
        let Humidity = this.state.Humidity
        Humidity.forEach(item => {
            if (item.checked === true) {
                humidityselected.push(item.value)
            }
        })
        // const mintemp = this.state.tempvalue[0];
        // const maxtemp = this.state.tempvalue[1];
        const minheight = this.state.heightvalue[0];
        const maxheight = this.state.heightvalue[1];
        var DisplayPlants = [];
        var lightplant = [];
        var humidityplant = [];
        var joined = Plant;
        if (categoriesselected.length === 0) {
            joined = []
        }
        else {
            categoriesselected.map(item => {
                Plant.map((plantitem => {
                    if ((plantitem.Category.toLowerCase().indexOf(item.toLowerCase()) !== -1) && plantitem.Max_Growth >= minheight && plantitem.Max_Growth <= maxheight)
                        DisplayPlants.push(plantitem)
                    return 0
                }))
                return 0
            })

            lightselected.map(item => {
                DisplayPlants.map((Category => {
                    if (Category.Light.toLowerCase().indexOf(item.toLowerCase()) !== -1)
                        lightplant.push(Category)
                    return 0

                }))
                return 0
            })
            humidityselected.map(item => {
                DisplayPlants.map(Category => {
                    if (Category.Humidity.toLowerCase().indexOf(item.toLowerCase()) !== -1)
                        humidityplant.push(Category)
                    return 0
                })
                return 0
            })
            joined = [];
            joined = lightplant.concat(humidityplant)
            joined = joined.filter((arr, index, self) =>
                index === self.findIndex((t) => (t.Name === arr.Name)))
        }
        return (
            <div className="findplantspos pt-4 above-area bg-24">
                <Helmet>
                    <title>Find Best Plants</title>
                </Helmet>
                <div className="my-4 text-center text-white">
                    <h1>Find Plants by your Preference</h1>
                </div>
                <Container>
                    <Row>
                        <Col sm="6" lg="3" xs="12" className="mx-auto mt-2">
                            <br />
                            <Card className="pref-cat">
                                <Card.Header>
                                    Category
                                </Card.Header>
                                <Card.Body>
                                    <div className="pl-2">
                                        <Form.Check onClick={this.handleAllCheckedCategory} type="checkbox" checked={this.state.checkedcatall} id="Select All Categories" label="Select All" />
                                        {this.state.Categories.map(item =>
                                            <Form.Check onClick={this.handleCheckChieldElementCategory} type="checkbox" label={item.value} {...item} />
                                        )}
                                    </div>
                                </Card.Body>

                                <Card.Header>
                                    Light
                                </Card.Header>
                                <Card.Body>
                                    <div className="pl-2">
                                        <Form.Check onClick={this.handleAllCheckedLight} type="checkbox" checked={this.state.checkedlightall} id="Select All Light" label="Select All" />
                                        {this.state.Light.map(item =>
                                            <Form.Check onClick={this.handleCheckChieldElementLight} type="checkbox" label={item.value} {...item} />
                                        )}
                                    </div>
                                </Card.Body>

                                <Card.Header>
                                    Humidity
                                </Card.Header>
                                <Card.Body>
                                    <div className="pl-2">
                                        <Form.Check onClick={this.handleAllCheckedHumidity} type="checkbox" checked={this.state.checkedhumidall} id="Select All Humidity" label="Select All" />
                                        {this.state.Humidity.map(item =>
                                            <Form.Check onClick={this.handleCheckChieldElementHumidity} type="checkbox" label={item.value} {...item} />
                                        )}
                                    </div>
                                </Card.Body>

                                <Card.Header>
                                    Height (in feet)
                                </Card.Header>
                                <Card.Body>
                                    <div className=" mx-auto">
                                        <Slider value={this.state.heightvalue} onChange={this.handleheightChange} min={0.2} max={20} step={0.4} valueLabelDisplay="auto" aria-labelledby="range-slider" getAriaValueText={this.heightvaluetext}
                                        />
                                    </div>
                                </Card.Body>
                            </Card>

                        </Col>
                        <Col sm="6" lg="9" xs="12">

                            <div className="plantspos">
                                <div className="searchpos mtnegative prefer">
                                    <div className="col-lg-12">
                                        <GoogleAd slot="5186751371" classNames="google-add-class" />
                                        {joined.map((Category, i) =>
                                            <div className=" col-lg-4 col-md-12 col-sm-12 col-9 d-inline-block mb-4" >
                                                <Link key={i} to={`/Category/${Category.Category}/${Category.Name}`} >
                                                    <div className="myborder row" style={{ height: "440px" }}>
                                                        <img src={process.env.PUBLIC_URL + `./Images/PlantImages/${(Category.Image_Name).toLowerCase()}.jpeg`} alt="No DP" />
                                                        <div className="mytext" style={{ height: "200px" }}>
                                                            <h3>{Category.Name}</h3>
                                                            <p >{Category.Botanical_Name}, {Category.Other_Names}<br /> <b>Category</b> - {Category.Category}</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>)}
                                        <GoogleAd slot="5186751371" classNames="google-add-class" />
                                    </div>
                                </div>
                            </div>)

                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
