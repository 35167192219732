import React, { Component } from 'react'
import { Helmet } from "react-helmet";
import { GoogleAd } from './Components';

export default class Diseases extends Component {
    render() {
        return (

            <div className="suggestionspos text-light above-area pt-4 bg-23">
                <Helmet>
                    <title>Common Diseases</title>
                </Helmet>
                <div className="container col-lg-8 col-12 common-diseases">
                    <h1 align="center" className="mt-4">Common Diseases in Plants</h1>
                    <div className=" container diseases">
                        <h2>1. BLACK LEG:- </h2>
                        <img src={process.env.PUBLIC_URL + "/Images/Diseases/D1.jpg"} alt='No DP Found' />
                        <p>Blackleg disease in cole crops is caused by the fungus Phoma lingam, which overwinters in soil, on crop debris and in infected seed. It’s easy to transmit from plant to plant and difficult to control without excellent sanitation practices. Blackleg can strike at any stage of development, but usually starts on seedlings two to three weeks from transplantation.</p>
                        <h4>CURE:-</h4>
                        <p>There is no effective treatment for either type of blackleg once it has taken hold, so it's important to prevent it from getting into your garden in the first place. So take prior precautions before it sabotages your indoor plants.</p>
                    </div>
                    <GoogleAd slot="5186751371" classNames="google-add-class" />
                    <div className=" container text-light diseases">
                        <h2>2. BOTRYTIS:- </h2>
                        <img src={process.env.PUBLIC_URL + "/Images/Diseases/D2.jpg"} alt='No DP Found' />
                        <p>Gray mold or Botrytis blight often begins with relatively large grayish to tan areas on older leaves or spent flowers.The dusty gray spores of the fungus are easily seen and infected areas can rapidly enlarge and cause tissue collapse.</p>
                        <h4>CURE:-</h4>
                        <p>1. Remove the infected plants and destroy them.<br />
                            2. Clean thoroughly between your plants so that the disease cannot infect your other plants.<br />
                            3. You can try using sprays with cultural controls on your plants to prevent further infections.
                        </p>
                    </div>
                    <div className=" container text-light diseases">
                        <h2>3. LEAF SPOTS:- </h2>
                        <img src={process.env.PUBLIC_URL + "/Images/Diseases/D3.jpg"} alt='No DP Found' />
                        <p>Fungal Leaf Spots on Houseplants. There are many species of fungi that can cause leaf spots on house plants (leaf spot on prayer plant). If the leaf spots attack the newest leaves near the top of the plant the growing point may be killed. Leaf spots can enlarge and grow onto branches and stems.</p>

                        <h4>CURE:-</h4>
                        <p>Apply sulphur sprays or copper-based fungicides weekly at first sign of disease to prevent its spread. These organic fungicides will not kill leaf spot, but prevent the spores from germinating. Safely treat most fungal and bacterial diseases with SERENADE Garden.</p>
                    </div>
                    <div className=" container text-light diseases">
                        <h2>4. POWDERY MILDEW:- </h2>
                        <img src={process.env.PUBLIC_URL + "/Images/Diseases/D4.jpg"} alt='No DP Found' />
                        <p>Powdery mildew on houseplants is a fungal disease. Initially, it produces circular powdery white spots on the foliage of plants. The indoor powdery mildew requires temperatures around 70 F (21 C). It occurs when there is poor air circulation, low light, and unlike outdoor powdery mildew, thrives in drier conditions.</p>

                        <h4>CURE:-</h4>
                        <p>Combine one tablespoon baking soda and one-half teaspoon of liquid, non-detergent soap with one gallon of water, and spray the mixture liberally on the plants. Mouthwash. The mouthwash you may use on a daily basis for killing the germs in your mouth can also be effective at killing powdery mildew spores.</p>
                    </div>
                    <GoogleAd slot="5186751371" classNames="google-add-class" />
                    <div className=" container text-light diseases">
                        <h2>5. ROOT ROT:- </h2>
                        <img src={process.env.PUBLIC_URL + "/Images/Diseases/D5.jpg"} alt='No DP Found' />
                        <p>One of the major issues for houseplants is root rot associated with overwatering. Root rot is the most common indoor plant disease we see in the Clinic this time of the year. Root rots are caused by several fungal and fungus-like organisms such as Pythium, Phytophthora, Rhizoctonia, and Fusarium.</p>

                        <h4>CURE:-</h4>
                        <p>Continue treating root rot by disposing of the soil in the pot that the plant was in. Wash the pot thoroughly with a bleach solution. If possible, dip the remaining healthy roots in a fungicide solution to kill off any possible root rot fungus. After treating root rot in the plant, repot the plant in clean potting mix.</p>
                    </div>
                    <div className=" container text-light diseases">
                        <h2>6. RUST:- </h2>
                        <img src={process.env.PUBLIC_URL + "/Images/Diseases/D6.jpg"} alt='No DP Found' />
                        <p>Rusts are not a major problem with house plants, but they can attack certain container-grown plants. However, with early treatment the damage can be kept to a minimum. The following are particularly susceptible to rust. Watch them carefully; if rust appears, treat immediately.</p>

                        <h4>CURE:-</h4>
                        <p> Select rust-resistant plant varieties when available.<br />
                            Pick off and destroy infected leaves and frequently rake under plants to remove all fallen debris.<br />
                            Water in the early morning hours — avoiding overhead sprinklers — to give plants time to dry out during the day. Drip irrigation and soaker hoses can be used to help keep leaves dry.<br />
                            Use a slow-release, organic fertilizer on crops and avoid excess nitrogen. Soft, leafy, new growth is most susceptible.
                        </p>
                    </div>
                    <div className=" container text-light diseases">
                        <h2>7. SOOTY MOLD:- </h2>
                        <img src={process.env.PUBLIC_URL + "/Images/Diseases/D7.jpg"} alt='No DP Found' />
                        <p>Sooty molds often start as small spots on houseplants, feeding on those tiny droplets of honeydew produced by a few sap-feeding pests. As the sap-feeding colony grows, sooty mold colonies expand, sometimes covering entire plant leaves and interfering with photosynthesis.</p>

                        <h4>CURE:-</h4>
                        <p>Use your sprayer and hose down the plant with a solution of dishwashing soap. This step does double-duty by cleaning up the sooty mold and killing some of the pests that cause sooty mold. Avoid using a degreaser or soap/detergent for an automatic washer.</p>
                    </div>
                    <div className=" container text-light diseases">
                        <h2>8. Crown and Stem Rot (Basal Stem Rot):- </h2>
                        <img src={process.env.PUBLIC_URL + "/Images/Diseases/D8.jpg"} alt='No DP Found' />
                        <p>This is caused by fungal mycelia which lives in most soils. It normally lives peacefully with the houseplant, however when conditions become very damp through overwatering, cool conditions or poor ventilation it rapidly multiplies and infects the plant.</p>

                        <h4>CURE:-</h4>
                        <p>The only treatment is to cut out the rot and dust with an anti fungicide such as sulphur. If the base has been very badly effected there is very little you can do because obviously if you're having to cut out whole lower sections of the plant you will kill it. It's therefore worth taking cuttings from the healthy parts and trying to propagate replacements.</p>
                    </div>
                    <div className=" container text-light diseases">
                        <h2>9. Nutrient deficiency:- </h2>
                        <img src={process.env.PUBLIC_URL + "/Images/Diseases/D9.jpg"} alt='No DP Found' />
                        <p>If you've never fed or repotted your plant then a nutrient deficiency is quite probable. The majority of plants make their own food through photosynthesis, however small levels of nutrients are needed to sustain that new growth and to ensure a healthy look.<br />The nutrients are found in the soil but once they're gone they're gone and therefore it's important nutrients are replaced either by a fertiliser or by changing the soil occasionally</p>

                        <h4>CURE:-</h4>
                        <p>The immediate treatment for your plant is to either fertilise using an all purpose mixture or to repot using fresh compost. Choose only one to start with, rather than both at the same time as this could result in too much fertiliser which can damage the plant further. The rule of thumb is to wait at least 8 weeks before you start fertilising newly repotted plants.</p>
                    </div>
                    <div className=" container text-light diseases">
                        <h2>10. Viruses:- </h2>
                        <img src={process.env.PUBLIC_URL + "/Images/Diseases/D10.jpg"} alt='No DP Found' />
                        <p>Viruses are a broad topic, the symptoms are varied and most types of plant are effected from time to time. They're typically spread by long term pests such as Aphids or by the plant being in close proximity to one that has already been effected with a virus.</p>

                        <h4>CURE:-</h4>
                        <p>There is no cure. If the symptoms can't be tolerated then you will need to throw it away. Don't try to propagate any part of the plant because there is a high possibility the virus will remain and prosper in the new plants.</p>
                    </div>
                    <div className=" container text-light diseases">
                        <h2>11. White Mold (or Mould):- </h2>
                        <img src={process.env.PUBLIC_URL + "/Images/Diseases/D11.jpg"} alt='No DP Found' />
                        <p>"What's the white mold growing in my houseplant's soil"? Is one of our frequently asked questions. It's another fungus that quickly colonise the soil surface when conditions are moist, humid and over watered.<br />Ventilation is also typically poor therefore it tends to appear more in cooler months of the year when everything in the home is shut up tight. Don't worry though, as white mold is easily dealt with and basically harmless to plants and people.</p>

                        <h4>CURE:-</h4>
                        <p>No need to reach for the sprays here. All you need do is gently rake up the effected soil using something like the end of a pencil or pen. You don't need to pick it out or anything like that so it's a very easy job. The raking could free up the fungal spores into the air though so do be careful not to inhale them as they may potentially trigger allergies and aggravate asthma.<br />After delivering the treatment think about moving your plant to a more ventilated space or try and free the growing medium by making it more "open".</p>
                    </div>
                </div>
            </div>

        )
    }
}
