import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { GoogleAd } from './Components';

export default class OurTeam extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div class="ourteampos text-white bg-3 above-area" style={{ backgroundSize: "cover" }} align="center">
                <Helmet>
                    <title>Our Team</title>
                </Helmet>
                <GoogleAd slot="5186751371" classNames="google-add-class" />
                <h1 className="mb-4" >Meet Our Team</h1>
                <div class="col-12">
                    <div className="col-lg-4 col-md-6 cards d-inline-block mb-4">
                        <img src={process.env.PUBLIC_URL + "/Images/Team/Prabhjot.png"} alt='Prabhjot Singh' />
                        <h1>Prabhjot Singh</h1>
                        <h5>Mentor</h5>
                    </div>
                    <div className=" col-lg-4 col-md-6 cards d-inline-block  mb-4 " >
                        <img src={process.env.PUBLIC_URL + "/Images/Team/Raj.jpg"} alt='Raj Aryan' />
                        <h1>Raj Aryan</h1>
                        <h5>Project Manager</h5>
                    </div>
                    <div className=" col-lg-4 col-md-6 cards d-inline-block  mb-4 " >
                        <img src={process.env.PUBLIC_URL + "/Images/Team/Kashish.png"} alt='Kashish Jangid' />
                        <h1>Kashish Jangid</h1>
                        <h5>IOT Specialist</h5>
                    </div>
                    <div className=" col-lg-4 col-md-6 cards d-inline-block  mb-4 " >
                        <img src={process.env.PUBLIC_URL + "/Images/Team/Deepak.jpg"} alt='Deepak Kapoor' />
                        <h1>Deepak Kapoor</h1>
                        <h5>Web Developer</h5>
                    </div>
                    <div className="col-lg-4 col-md-6 cards d-inline-block mb-4 " >
                        <img src={process.env.PUBLIC_URL + "/Images/Team/Rishab.jpeg"} alt='Rishabh Aggarwal' />
                        <h1>Rishab Aggarwal</h1>
                        <h5>App Developer</h5>
                    </div>
                    <div className="col-lg-4 col-md-6 cards d-inline-block  mb-4 " >
                        <img src={process.env.PUBLIC_URL + "/Images/Team/Sumit.png"} alt='Sumit Anand' />
                        <h1>Sumit Anand</h1>
                        <h5>Marketing Manager</h5>
                    </div>
                    <div className="col-lg-4 col-md-6 cards d-inline-block  mb-4 " >
                        <img src={process.env.PUBLIC_URL + "/Images/Team/Himanshu.png"} alt='Himanshu' />
                        <h1>Himanshu Soni</h1>
                        <h5>Marketing Manager</h5>
                    </div>
                    <div className="col-lg-4 col-md-6 cards d-inline-block  mb-4 " >
                        <img src={process.env.PUBLIC_URL + "/Images/Team/Pardeep.png"} alt='Pardeep Kumar' />
                        <h1>Pardeep Kumar</h1>
                        <h5>Product Developer</h5>
                    </div>
                    <div className="col-lg-4 col-md-6 cards d-inline-block  mb-4 " >
                        <img src={process.env.PUBLIC_URL + "/Images/Team/Hitesh.jpeg"} alt='Hitesh Bansal' />
                        <h1>Hitesh Bansal</h1>
                        <h5>Product Designer</h5>
                    </div>
                    <div className="col-lg-4 col-md-6 cards d-inline-block  mb-4" >
                        <img src={process.env.PUBLIC_URL + "/Images/Team/Sneha.png"} alt='Sneha Jha' />
                        <h1>Sneha Jha</h1>
                        <h5>Content Designer</h5>
                    </div>
                    <div className="col-lg-4 col-md-6 cards d-inline-block  mb-4 " >
                        <img src={process.env.PUBLIC_URL + "/Images/Team/Krishna.png"} alt='KrishnaPriya' />
                        <h1>KrishnaPriya</h1>
                        <h5>Content Designer</h5>
                    </div>
                </div>
                <GoogleAd slot="5186751371" classNames="google-add-class" />
            </div>
        )
    }
}
