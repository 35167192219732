import React, { PureComponent } from 'react';
import { Carousel } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import { Carous, Carous1 } from './Components'
import { GoogleAd } from './Components';

export default class Home extends PureComponent {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>MyPlantsTalk</title>
                </Helmet>
                <div className="bg-overlay mycarousel mt-200">
                    <Carousel>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={process.env.PUBLIC_URL + "/Images/BG/1.jpg"}
                                alt="First slide"
                            />
                            <Carousel.Caption>
                                <h2>A place where you get real time monitoring of your Plant</h2>
                                <p>Indoor plants can reduce both physiological and psychological stress. With even one plants you can contribute oxygen to the environment. Plant smiles, Grow laughter, Harvest love</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100 "
                                src={process.env.PUBLIC_URL + "/Images/BG/2.jpg"}
                                alt="Second slide"
                            />

                            <Carousel.Caption>
                                <h2>A place where you get real time monitoring of your Plant</h2>
                                <p>Indoor plants can reduce both physiological and psychological stress. With even one plants you can contribute oxygen to the environment. Plant smiles, Grow laughter, Harvest love</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                </div>
                {/* <div className="coming-soon">
                    <img src={require("./Images/BG/Coming-Soon.jpg")} alt="Coming Soon"
                    />
                </div> */}

                <Carous1 heading="Our Brand New Product and App Coming Soon" linking="/Plants" clsname="carous0" desc="We are launching our brand new Product and App very soon. Our new product will help you to montior your plants growth 24x7 and Our app will help you to visualize the live data." />
                <Carous heading="About Us" linking="/Plants" clsname="carous1" desc="The main objective of MyPlantsTalk is controlling indoor pollution, encouraging people to grow more and more plants indoor and contribute to the environment. With MyPlantsTalk we have tried to eliminate the possibility which may be an obstacle in the plantation. With the help of MyPlantsTalk we can grow plants with least human interaction and best growth to your plants." />
                <GoogleAd slot="5186751371" classNames="google-add-class" />
                <Carous1 heading="Search Plants" linking="/Plants" clsname="carous2" desc="Confused which plant to choose!!! Here, select your plant easily as plants are divided into different categories." />
                <Carous heading="Sort Plants by your Preference" linking="/FindPlants" clsname="carous3" desc="Customize your search for the plant according to your preference." />
                <Carous1 heading="Find Plants by your Location" linking="/Suggestions" clsname="carous4" desc="Don’t know which plant will grow best in your city!! Here you will find the best plants suited to the city you live in…." />
                <GoogleAd slot="5186751371" classNames="google-add-class" />
                <Carous heading="Diseases in Plants" linking="/Diseases" clsname="carous6" desc="Most plant diseases – around 85 percent – are caused by fungal or fungal-like organisms. However, other serious diseases of food and feed crops are caused by viral and bacterial organisms. Check here about various diseases in plants." />
                <Carous1 heading="Any Suggestions for us!!" linking="/Feedback" clsname="carous5" desc="Something missing!!!! Want to add new plant to our database… Want to suggest something... Or any kind of feedback… Feel free to let us know… " />

                {/* <div className="section-heading text-center mt-4">
                    <h2 >Meet Our Team</h2>
                </div>
                
                
                <Coverflow height="400" displayQuantityOfSide={2} navigation={false} enableScroll={false} clickable={true} active={1}>
                    <img src={require("./Images/Team/Sneha.png")} alt='Sneha Jha'/>
                    <img src={require("./Images/Team/Himanshu.png")} alt='Himanshu'/>
                    <img src={`https://joeschmoe.io/api/v1/Rishab Aggarwal`} alt='Rishabh Aggarwal'/>
                    <img src={require("./Images/Team/Kashish.png")} alt='Kashish Jangid'/>
                    <img src={require("./Images/Team/Raj.jpg")} alt='Raj Aryan'/>
                    <img src={require("./Images/Team/Prabhjot.png")} alt='Prabhjot Singh'/>
                    <img src={require("./Images/Team/Deepak.jpg")} alt='Deepak Kapoor'/>
                    <img src={require("./Images/Team/Sumit.png")} alt='Sumit Anand'/>
                    <img src={require("./Images/Team/Pardeep.png")} alt='Pardeep Kumar'/>
                    <img src={require("./Images/Team/Krishna.png")} alt='KrishnaPriya'/>
                </Coverflow> */}
            </div>
        )
    }
}