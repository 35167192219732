import React, { Component } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { Link, withRouter } from 'react-router-dom'

class Header extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Navbar collapseOnSelect expand="lg" className="mt-negative" style={{ backgroundColor: "#202020" }}>

                <Nav >
                    <Navbar.Brand>
                        <Nav.Link as={Link} to="/Home"><span><img src={process.env.PUBLIC_URL + '/MyPlantsTalk-Logo.png'} alt="Logo" /></span><div className="d-inline" style={{ width: "80px" }}>My<span>Plants</span>Talk<br /><p>You Plant We Care</p></div></Nav.Link>
                    </Navbar.Brand>
                </Nav>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto ">
                        <Nav.Link eventKey={1} as={Link} to="/Home">Home</Nav.Link>
                        <Nav.Link eventKey={2} as={Link} to="/Plants">Search</Nav.Link>
                        <Nav.Link eventKey={3} as={Link} to="/FindPlants">Customize</Nav.Link>
                        <Nav.Link eventKey={5} as={Link} to='/Suggestions'>Location Wise Plants</Nav.Link>
                        <Nav.Link eventKey={6} as={Link} to='/Feedback' > Feedback</Nav.Link>
                        <Nav.Link eventKey={6} as={Link} to='/OurTeam' > Our Team</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}

export default withRouter(Header);