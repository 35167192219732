import React, { Component } from 'react'
import Plant from './Plant.json'
import { Helmet } from "react-helmet";
import { GoogleAd } from './Components';
export default class PlantDetail extends Component {
    state = {
        Category_Name: this.props.match.params.category_name,
        Plant_Name: this.props.match.params.plant_name
    }
    render() {
        const CategoryDetail = Plant.filter(Category => {
            return (Category.Name === this.state.Plant_Name && Category.Category === this.state.Category_Name)
        })
        console.log(CategoryDetail)
        return (
            <div className="bg-35 plantsdetailbg">
                <div className="container  d-flex text-white justify-content-center plantdetail">
                    <Helmet>
                        <title>{this.state.Plant_Name} - {this.state.Category_Name}</title>
                    </Helmet>
                    <GoogleAd slot="5186751371" classNames="google-add-class" />
                    {
                        CategoryDetail.map((Category, i) =>
                            <div className="col-lg-6 col-md-6 displayplantdetail" >
                                <img src={process.env.PUBLIC_URL + `./Images/PlantImages/${(Category.Image_Name).toLowerCase()}.jpeg`} alt="No DP" />
                                <div className="mytext">
                                    <br />
                                    <h1>{Category.Name}</h1><hr />
                                    <p><h5><b>Botanical_Name - </b></h5>{Category.Botanical_Name}</p>
                                    <p><h5><b>Other Names - </b></h5>{Category.Other_Names}</p>
                                    <p><h5><b>Category - </b></h5>{Category.Category}</p>
                                    <p><h5><b>Light Required - </b></h5>{Category.Light}</p>
                                    <p><h5><b>Fertilizers Required - </b></h5>{Category.Fertilizer}</p>
                                    <p><h5><b>Re-Potting - </b></h5>{Category.Re_Potting}</p>
                                    <p><h5><b>Temperature - </b></h5>The Critical Temperature required for this plant to grow is {Category.Min_Temp}&deg;C and Ideal Temperature is {Category.Max_Temp}&deg;C</p>
                                    <p><h5><b>Maximum Growth of Plant - </b></h5>{Category.Max_Growth} feet</p>
                                    <p><h5><b>Watering Required - </b></h5>{Category.Watering}</p>
                                    <p><h5><b>Soil - </b></h5>{Category.Soil}</p>
                                    <p><h5><b>Soil pH - </b></h5>{Category.Soil_pH}</p>
                                    <p><h5><b>Propagation - </b></h5>{Category.Propagation}</p>
                                    <p><h5><b>Humidity Required - </b></h5>{Category.Humidity}</p>
                                    <p><h5><b>Bloom Time - </b></h5>{Category.Bloom_Time}</p>
                                    <p><h5><b>Flower Color - </b></h5>{Category.Flower_Color}</p>
                                </div>
                            </div>
                        )
                    }
                    <GoogleAd slot="5186751371" classNames="google-add-class" />
                </div >
            </div>

        )
    }
}
