import React from "react";
import { Button, Container, Row, Col, UncontrolledCarousel } from "reactstrap";
import { Link } from 'react-router-dom'

class Carous extends React.Component {
    render() {
        var items = []
        if (this.props.clsname === "carous1") {
            items = [
                {
                    src: process.env.PUBLIC_URL + "/Images/BG/23.jpg",
                    altText: "",
                    caption: "",
                    header: ""
                },
                {
                    src: process.env.PUBLIC_URL + "/Images/BG/19.jpg",
                    altText: "",
                    caption: "",
                    header: ""
                }
            ];
        }

        else if (this.props.clsname === "carous3") {
            items = [
                {
                    src: process.env.PUBLIC_URL + "/Images/BG/1.jpg",
                    altText: "",
                    caption: "",
                    header: ""
                },
                {
                    src: process.env.PUBLIC_URL + "/Images/BG/3.jpg",
                    altText: "",
                    caption: "",
                    header: ""
                }
            ];
        }

        else if (this.props.clsname === "carous5") {
            items = [
                {
                    src: process.env.PUBLIC_URL + "/Images/BG/1.jpg",
                    altText: "",
                    caption: "",
                    header: ""
                },
                {
                    src: process.env.PUBLIC_URL + "/Images/BG/3.jpg",
                    altText: "",
                    caption: "",
                    header: ""
                }
            ];
        }
        else {
            items = [
                {
                    src: process.env.PUBLIC_URL + "/Images/BG/D1.jpg",
                    altText: "",
                    caption: "",
                    header: ""
                },
                {
                    src: process.env.PUBLIC_URL + "/Images/BG/D2.jpg",
                    altText: "",
                    caption: "",
                    header: ""
                }
            ];

        }

        return (
            <>
                <section className="section section-shaped">
                    <div className={"shape shape-style-1 shape-default " + this.props.clsname}>
                        <span />
                        <span />
                        <span />
                        <span />
                        <span />
                        <span />
                    </div>
                    <Container className="py-md section-box">
                        <Row className="justify-content-between align-items-center">
                            <Col className="mb-5 mb-lg-0" lg="6">
                                <h1 className="text-white font-weight-light">
                                    {this.props.heading}
                                </h1>
                                <p className="lead text-white mt-4 text-justify">
                                    {this.props.desc}
                                </p>
                                {(this.props.clsname !== "carous1") ?
                                    <Link to={this.props.linking} target="_blank">
                                        <Button
                                            className="btn-white mt-4"
                                            color="default"
                                        >
                                            {this.props.heading}
                                        </Button>
                                    </Link> : ""
                                }
                            </Col>
                            <Col className="mb-lg-auto" lg="6">
                                <div className="rounded shadow-lg overflow-hidden transform-perspective-right">
                                    <UncontrolledCarousel items={items} captionText="" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    {/* SVG separator */}
                    {/* <div className="separator separator-bottom separator-skew">
                        <svg
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon className="fill-white" points="2560 0 2560 100 0 100" />
                        </svg>
                    </div> */}
                </section>
            </>
        );
    }
}

export default Carous;
