import React, { Component } from 'react'
import Plant from './Plant.json'
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { GoogleAd } from './Components';

export default class CategoryDetail extends Component {
    state = {
        Category_Name: this.props.match.params.category_name,

    }
    render() {
        console.log(this.state.Category_Name)
        const CategoryDetail = []
        Plant.filter(Category => {
            if (Category.Category.toLowerCase().indexOf(this.state.Category_Name.toLowerCase()) !== -1)
                CategoryDetail.push(Category)
            return 0
        })
        const detaillength = CategoryDetail.length;
        return (
            <div className="searchpos plantspos plantsbg bg-23 text-white py-4">
                <Helmet>
                    <title>{this.state.Category_Name}</title>
                </Helmet>
                <br /><br />
                <h1>{this.state.Category_Name} Plants</h1>
                <p>{detaillength} {detaillength > 1 ? "Plants" : "Plant"} found</p><br /><br />
                <GoogleAd slot="5186751371" classNames="" />
                <div className="col-lg-12">
                    {CategoryDetail.map((Category, i) =>
                        <div key={i} className=" col-lg-5 col-md-6 d-inline-block  mb-4 searchresults" >
                            <Link key={i} to={`/Category/${Category.Category}/${Category.Name}`}>
                                <div className="myborder row">

                                    <img src={process.env.PUBLIC_URL + `./Images/PlantImages/${(Category.Image_Name).toLowerCase()}.jpeg`} alt="No DP" />
                                    <div className="mytext">
                                        <h3>{Category.Name}</h3>
                                        <p>{Category.Botanical_Name}</p>
                                    </div>
                                </div>
                            </Link>
                        </div>)}
                    <GoogleAd slot="5186751371" classNames="" />

                </div>
            </div>
        )
    }
}
