import React, { Component } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default class Footer extends Component {
    render() {
        return (
            <footer className="footer-area bg-3">

                <div className="main-footer-area">
                    <div className="container">
                        <div className="row">


                            <div className="col-12 col-sm-6 col-lg-8">
                                <div className="single-footer-widget text-white">

                                    <Navbar.Brand>
                                        <Nav.Link as={Link} to="/Home"><span><img src={process.env.PUBLIC_URL + "/Images/Core/MyPlantsTalk-Logo.png"} alt="Logo" /></span><div className="d-inline" style={{ width: "80px" }}>My<span>Plants</span>Talk<br /><p>You Plant We Care</p></div></Nav.Link>
                                    </Navbar.Brand>
                                    <br /><br />
                                    <div className="social-info mb-4">
                                        <a href="https://www.facebook.com/deepak.kapoor.1905" title="Facebook" target="__blank"><i className="fa fa-facebook" aria-hidden="true"></i> </a>&nbsp;
                                        <a href="https://www.instagram.com/myplantstalk/" title="Instagram" target="__blank"><i className="fa fa-instagram" aria-hidden="true"></i> </a>&nbsp;
                                        <a href="mailto:wethelocalizers@gmail.com" title="Email Me" target="__blank"><i className="fa fa-envelope" aria-hidden="true"></i> </a>
                                    </div>
                                </div>
                            </div>



                            <div className="col-12 col-sm-6 col-lg-4 float-right">
                                <div className="single-footer-widget">
                                    <div className="widget-title">
                                        <h5>CONTACT</h5>
                                    </div>

                                    <div className="contact-information">
                                        <p><span>Phone:</span> +91 8054259776</p>
                                        <p><span>Email:</span> wethelocalizers@gmail.com</p>
                                        <p><span>Open hours:</span> Mon - Sun: 8 AM to 9 PM</p>
                                        <p><span>Happy hours:</span> Sat: 2 PM to 4 PM</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="footer-bottom-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="border-line"></div>
                            </div>

                            <div className="col-12 col-md-6">
                                <div className="copywrite-text">
                                    <p>

                                        Copyright &copy;
                                {new Date().getFullYear()} All rights reserved

                                    </p>
                                </div>
                            </div>

                            <div className="col-12 col-md-6">
                                <div className="footer-nav">
                                    <nav>
                                        <ul>
                                            <li><Nav.Link as={Link} to="/Home">Home</Nav.Link></li>
                                            <li><Nav.Link as={Link} to="/Plants">Search</Nav.Link></li>
                                            <li><Nav.Link as={Link} to="/FindPlants">Customize</Nav.Link></li>
                                            <li><Nav.Link as={Link} to="/Suggestions">Location Wise Plants</Nav.Link></li>
                                            <li><Nav.Link as={Link} to="/Feedback">Feedback</Nav.Link></li>
                                            <li><Nav.Link as={Link} to="/OurTeam">Our Team</Nav.Link></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
