import React, { Component } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import * as emailjs from 'emailjs-com'
import { Helmet } from "react-helmet";
import { Form, InputGroup, Button, Modal } from 'react-bootstrap'
import { GoogleAd } from './Components';

export default class Feedback extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    state = {
        isRedirect: false,
        show: false
    }
    handleClose = () => {
        this.setState({ isRedirect: true });
    }

    render() {

        var phoneNum = /^\s*(?:\+?(\d{1,3}))?[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/
        const schema = yup.object({
            fullName: yup.string().required("Please Enter Name"),
            Email: yup.string().email().required(),
            Contact: yup.string().required("Please Enter Valid Phone Number").matches(phoneNum, 'Phone number is not valid')
                .max(10, "Maximum 10 digits allowed"),
            Feedback: yup.string().required("Feedback can't be empty field"),
            plantname: yup.string(),
            plantcategory: yup.string()

        });

        const isRedirect = this.state.isRedirect;
        if (isRedirect === true) {
            window.location = '/MyPlantsTalk'
        }
        return (
            <div className="pt-4 feedbackpos above-area bg-24">
                <Helmet>
                    <title>Feedback</title>
                </Helmet>
                <div className="text-center text-light my-4" >
                    <h1>Your Feedback is very important for us</h1>
                    <p>Please enter following details so that we can make ourself better</p>
                </div>
                <Formik
                    validationSchema={schema}
                    onSubmit={(values) => {
                        const output = `
                                    <h2>Contact Details</h2>
                                    <ul>  
                                    <li><h3>Name: </h3>${values.fullName}</li>
                                    <li><h3>Email:</h3> ${values.Email}</li>
                                    <li><h3>Contact: </h3>${values.Contact}</li>
                                    <li><h3>Suggested Plant Name: </h3>${values.plantname}</li>
                                    <li><h3>Suggested Plant Category: </h3>${values.plantcategory}</li>
                                    </ul>
                                    <h2>Feedback/Suggestions</h2>
                                    <p>${values.Feedback}</p>
                                `;
                        let templateParams = {
                            from_name: values.fullName,
                            to_name: 'MyPlantsTalk Team',
                            subject: "MyPlantsTalk Feedback Received",
                            message_html: output,
                        }
                        emailjs.send(
                            'gmail',
                            'MyPlantsTalk',
                            templateParams,
                            'user_j06PQALP36QLk36cboLRD'
                        )
                        this.setState({ show: true })
                    }
                    }
                    initialValues={{
                        fullName: "",
                        Email: "",
                        Contact: "",
                        plantname: "",
                        plantcategory: "",
                        Feedback: ""
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        errors,
                    }) => (
                        <Form noValidate onSubmit={handleSubmit} className="container col-md-4">
                            <GoogleAd slot="5186751371" classNames="google-add-class" />
                            <Form.Group controlId="inputname">
                                <Form.Label>Full name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="fullName"
                                    placeholder="Enter Full Name"
                                    value={values.fullName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.fullName && errors.fullName}
                                />
                                <Form.Control.Feedback type="invalid">{errors.fullName}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="inputemail">
                                <Form.Label>Email</Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        type="email"
                                        placeholder="Email"
                                        aria-describedby="inputGroupPrepend"
                                        name="Email"
                                        value={values.Email}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={touched.Email && errors.Email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.Email}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group controlId="inputcontact">
                                <Form.Label>Contact Number</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Don't Include +91"
                                    name="Contact"
                                    value={values.Contact}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.Contact && errors.Contact}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.Contact}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="inputplantname">
                                <Form.Label>Any Plant you want to include in our system</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Plant Name Here"
                                    name="plantname"
                                    value={values.plantname}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.plantname && errors.plantname}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.plantname}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="inputplantcategory">
                                <Form.Label>Your Suggested plant falls under which category</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter category here"
                                    name="plantcategory"
                                    value={values.plantcategory}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.plantcategory && errors.plantcategory}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.plantcategory}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="inputfeedback">
                                <Form.Label>Your Suggestions or Complaints</Form.Label><br />
                                <Form.Control
                                    as="textarea"
                                    rows="4"
                                    placeholder="Type Here"
                                    name="Feedback"
                                    value={values.Feedback}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.Feedback && errors.Feedback}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.Feedback}
                                </Form.Control.Feedback>
                            </Form.Group>


                            <Button type="submit" className="d-flex mx-auto">Submit Feedback</Button>
                        </Form>
                    )
                    }
                </Formik >
                < Modal show={this.state.show} onHide={this.handleClose} >
                    <Modal.Header closeButton>
                        <Modal.Title>Feedback Received</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Thank you for providing your valuable feedback.
                        We will definitely look into it.</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.handleClose}>
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>
                <GoogleAd slot="5186751371" classNames="google-add-class add-margin-top" />
            </div >

        )
    }
}
