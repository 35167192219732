import React, { PureComponent } from 'react'
import { InputGroup, FormControl } from 'react-bootstrap'
import Categories from './Categories.json'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";
import Plant from './Plant.json'
import { GoogleAd } from './Components';

export default class Plants extends PureComponent {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    state = {
        show: false,
        currentCategory: "",
        Details: [],
        PlantSearch: [],
        SearchResult: [],
        Searchingvalue: ""
    }
    handleChange = e => {
        this.setState({
            Searchingvalue: e.target.value,
            SearchResult: Plant.filter(Category => {
                return ((Category.Name.toLowerCase().indexOf(this.state.Searchingvalue.toLowerCase()) !== -1) || (Category.Botanical_Name.toLowerCase().indexOf(this.state.Searchingvalue.toLowerCase()) !== -1) || (Category.Other_Names.toLowerCase().indexOf(this.state.Searchingvalue.toLowerCase()) !== -1));
            })
        })
    }
    render() {
        return (
            <div className="plantspos plantsbg bg-23 text-white py-4">
                <Helmet>
                    <title>Categories</title>
                </Helmet>

                <InputGroup className="inpgrp col-md-4 d-flex mx-auto">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1" className="fa fa-search"></InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        style={{ height: "63px" }}
                        placeholder="Search for your favourite Plants here"
                        onChange={this.handleChange}
                        onKeyPress={this.handleChange}
                        onKeyUp={this.handleChange}
                    />
                </InputGroup>
                {(this.state.Searchingvalue.length <= 0) ?
                    <div className="container ">
                        <h1>Categories of Plants</h1><br /><br />
                        {Categories.map((item, i) => (
                            <Link key={i} to={`/Category/${item.Category}`} className="col-lg-4 col-md-6 cards 
                            mb-4 d-inline-block">
                                <img src={process.env.PUBLIC_URL + `./Images/Categories/${item.ImgName}.jpg`} alt="No DP" />
                                <h1 className="text-center">{item.Category}</h1>
                            </Link>
                        ))}
                    </div>
                    :
                    <div className="searchpos">
                        <h2>Search Results</h2><br /><br />
                        {(this.state.SearchResult.length > 0) ?
                            <div class="col-lg-12">
                                <GoogleAd slot="5186751371" classNames="" />
                                {this.state.SearchResult.map((Category, i) =>
                                    <div className=" col-lg-5 col-md-6 d-inline-block  mb-4 searchresults" >
                                        <Link key={i} to={`/Category/${Category.Category}/${Category.Name}`}>
                                            <div className="myborder row">
                                                <img src={process.env.PUBLIC_URL + `./Images/PlantImages/${(Category.Image_Name).toLowerCase()}.jpeg`} alt="No DP" />
                                                <div className="mytext">
                                                    <h3>{Category.Name}</h3>
                                                    <p>{Category.Botanical_Name}, {Category.Other_Names}<br /> Category - {Category.Category}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>)}
                            </div> :
                            <div className="noplantfound">
                                <h3>Sorry!! No Plants found for this Name</h3>
                                <GoogleAd slot="5186751371" classNames="" />
                            </div>
                        }
                    </div>}
            </div>

        )
    }

}
