import React, { Component } from 'react'
import { HashRouter as Router, Route } from 'react-router-dom'
import { Home, Header, Footer, Plants, FindPlants, Suggestions, Diseases, Feedback, OurTeam, CategoryDetail, PlantDetail } from './Components'
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "./App.css";

class App extends Component {

  render() {
    return (
      <Router>
        <Header />
        <Route path='/' exact component={Home} />
        <Route path='/Home' exact component={Home} />
        <Route path='/Plants' exact component={Plants} />
        <Route path='/FindPlants' exact component={FindPlants} />
        <Route path='/Suggestions' exact component={Suggestions} />
        <Route path='/Feedback' exact component={Feedback} />
        <Route path='/OurTeam' exact component={OurTeam} />
        <Route path='/Diseases' exact component={Diseases} />
        <Route path='/Category/:category_name' exact component={CategoryDetail} />
        <Route path='/Category/:category_name/:plant_name' exact component={PlantDetail} />
        <Footer />
      </Router >
    )
  }

}

export default App;

