import React, { Component } from 'react'
import { Form, Button } from 'react-bootstrap'
import { Helmet } from "react-helmet";
import State_Temp from './State_Temp.json'
import Plant from './Plant.json'
import { Link } from 'react-router-dom'
import states from './States.json';
import top10 from './Top10.json';
import cities from './Cities.json';
import { GoogleAd } from './Components';

export default class Suggestions extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    state = {
        CitySelected: null,
        SearchResult: [],
        top10selected: [],
        SelectedState: null,
        SortedCities: [],
        SortedPlants: []
    }
    handleStateSelect = (e) => {
        this.setState({
            SortedCities: cities.filter(item => {
                return (item.state_id === e.target.value)
            }),
            SelectedState: states.filter(item => {
                return (item.id === e.target.value)
            }),
            SearchResult: [],
            CitySelected: null
        })

    }
    handleCitySelect = (e) => {
        this.setState({
            CitySelected: e.target.value,
            SearchResult: []
        })

    }

    handleClick = () => {
        if (this.state.SelectedState && this.state.CitySelected) {
            var statename = this.state.SelectedState[0].name;
            this.setState({
                SearchResult: State_Temp.filter(State => {
                    return (State.State_Name.toLowerCase() === statename.toLowerCase());
                })
            })
        }

    }
    shuffle = (arra1) => {
        var ctr = arra1.length, temp, index;

        // While there are elements in the array
        while (ctr > 0) {
            // Pick a random index
            index = Math.floor(Math.random() * ctr);
            // Decrease ctr by 1
            ctr--;
            // And swap the last element with it
            temp = arra1[ctr];
            arra1[ctr] = arra1[index];
            arra1[index] = temp;
        }
        return arra1;
    }

    render() {
        if (this.state.SearchResult.length === 1) {
            const top10selected = top10.filter(item => {
                return (item.State === this.state.SearchResult[0].State_Name)
            })
            console.log(top10selected);
            var res = [];
            var SortedPlants = [];

            for (var i in top10selected[0]) {
                res.push(top10selected[0][i]);
            }
            res.forEach(val => {
                SortedPlants = Plant.filter(item => {
                    return (val.toLowerCase() === item.Name.toLowerCase())
                });
                return 0;
            });
            const min = this.state.SearchResult[0].Temp - 2;
            const max = this.state.SearchResult[0].Temp + 2;
            var Shuffled = this.shuffle(Plant);
            Shuffled.filter(Category => {
                if (Category.Max_Temp >= min && Category.Max_Temp <= max) {
                    SortedPlants.push(Category);
                }
                return 0;
            })
            SortedPlants = SortedPlants.filter((arr, index, self) =>
                index === self.findIndex((t) => (t.Name === arr.Name)))

            SortedPlants = SortedPlants.slice(0, 25);
        }

        return (

            <div className="suggestionspos above-area text-center pt-4 bg-24">
                <Helmet>
                    <title>Suggestions</title>
                </Helmet>
                <div className="text-center text-light my-4">
                    <h3>Please Select your State and City so that we can find best plants for you</h3>
                </div>
                <div className="container col-9 pt-4">
                    <Form.Control as="select" size="md" className="color-fade col-lg-3 col-md-4 mr-4 d-inline-block mb-4" custom>
                        <option value="" disabled selected hidden>India</option>
                    </Form.Control>

                    <Form.Control as="select" size="md" className="col-lg-3 col-md-4 mr-4 d-inline-block mb-4" custom onChange={this.handleStateSelect}>
                        <option value="" disabled selected hidden>Select State</option>
                        {states.map(item => <option key={item.name} value={item.id}>{item.name}</option>)}
                    </Form.Control>

                    <Form.Control as="select" size="md" className="col-lg-3 col-md-4 mr-4 d-inline-block mb-4" custom onChange={this.handleCitySelect}>
                        <option value="" selected>Select City</option>
                        {this.state.SortedCities.map(item => <option key={item.id} value={item.name}>{item.name}</option>)}
                    </Form.Control>

                    <Button className="col-lg-2 col-md-3 d-inline-block mb-4" onClick={this.handleClick} >Search Plants</Button>
                    <GoogleAd slot="5186751371" classNames="google-add-class" />
                </div>

                <div className="text-white">
                    {(this.state.SearchResult.length === 0) ?
                        <div></div> :
                        (SortedPlants.length > 0) ?
                            <div className="plantspos">
                                <div className="searchpos mtnegative">
                                    <div className="col-lg-12">
                                        <h5>Top {SortedPlants.length} Plants found which are Best to grow in {this.state.CitySelected}, {this.state.SelectedState[0].name}.</h5><br />
                                        {SortedPlants.map((Category, i) =>
                                            <div className=" col-lg-5 col-md-6 d-inline-block  mb-4 searchresults" >
                                                <Link key={i} to={`/Category/${Category.Category}/${Category.Name}`}>
                                                    <div className="myborder row">
                                                        <img src={process.env.PUBLIC_URL + `./Images/PlantImages/${(Category.Image_Name).toLowerCase()}.jpeg`} alt="No DP" />
                                                        <div className="mytext">
                                                            <h3>{Category.Name}</h3>
                                                            <p>{Category.Botanical_Name}, {Category.Other_Names}<br /> Category - {Category.Category}</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>)}
                                    </div>
                                </div>
                            </div> :
                            <div className="mt-4">
                                <h5>Oops! We didn't found any best plants for {this.state.CitySelected}, {this.state.SelectedState[0].name}.<br />
                                    We are collecting more data and will be back soon with more accurate suggestions for you.<br />
                                    Sorry for the Inconvience!! </h5>
                            </div>
                    }
                    <GoogleAd slot="5186751371" classNames="google-add-class" />
                </div>
            </div>

        )
    }
}
